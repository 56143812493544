import '../App.css';
import React from 'react';

export function LandingLaptop() {
  return (
    <div className="bg-midnight min-h-screen place-content-center">
      <div className='flex justify-center'>
      <div className="App-logo w-[208px] h-[130px]">
          <div className='circle circleLeft w-[130px] h-[130px] leftLogoMove'></div>
          <div className='circle circleRight w-[130px] h-[130px] rightLogoMove'></div>
        </div>
      </div>
      <div className='flex justify-center'>
      <div className='font-roboto bg-clip-text text-transparent py-2 bg-gradient-to-r from-primaryGreen to-primaryBlue font-extrabold text-5xl text-center'>
          Page is under construction, please comeback soon!
        </div>
      </div>
    <div className='flex justify-center mt-5'>
      <div className='transition-all ease-in-out duration-150 border border-transparent border-1 hover:border-primaryBlue rounded-lg drop-shadow-xl'>
      <button className='text-3xl px-5 py-2 text-white font-bold'>
          <a href='/' target='_blank' rel="noreferrer">Go to Home Page</a>
      </button>
      </div>

    </div>
    </div>
  );
}

export function LandingMobile() {
  return (
    <div className="bg-midnight min-h-screen place-content-center">
      <div className='flex justify-center'>
      <div className="App-logo w-[208px] h-[130px]">
          <div className='circle circleLeft w-[130px] h-[130px] leftLogoMove'></div>
          <div className='circle circleRight w-[130px] h-[130px] rightLogoMove'></div>
        </div>
      </div>
      <div className=''>
        <div className='font-roboto bg-clip-text text-transparent py-2 bg-gradient-to-r from-primaryGreen to-primaryBlue font-extrabold text-5xl text-center'>
          Portfolio v2 mobile version coming soon...
        </div>
        <div className='font-roboto bg-clip-text text-transparent py-2 bg-gradient-to-r from-primaryGreen to-primaryBlue font-extrabold text-3xl text-center'>
          Please switch to desktop view for best experience!
        </div>
      </div>
    <div className='flex justify-center mt-5'>
      <div className='transition-all ease-in-out duration-150 border border-transparent border-1 hover:border-primaryBlue rounded-lg drop-shadow-xl'>
      <button className='text-3xl px-5 py-2 text-white font-bold'>
          <a href='https://tylerddao.com' target='_blank' rel="noreferrer">Go to my current portfolio</a>
      </button>
      </div>

    </div>
    </div>
  );
}
