import './App.css';
import React from 'react';
import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {LandingLaptop, LandingMobile} from './Pages/LandingTemp';
import {HomeDesktop, HomeMobile} from './Pages/Home';
import {NotFound} from './Pages/NotFound'
import {ResponsiveDropdown} from './Components/DropBox'
function App() {
  return (
    <Router>
      <title>Tyler's Portfolio</title>
      <meta name="description" content="Hi, welcome to my portfolio, where I showcase my skills, my knowledges. Please take a look!" />
      <div className="sm:block md:hidden">
        <Routes>
          {/* <Route path='/' element={<LandingMobile/>}/> */}
          <Route path='*' element={<NotFound/>}/>
          <Route path='/' element={<HomeMobile/>}/>
          <Route path='/Landing' element={<LandingMobile/>}/>

        </Routes>
      </div>

      <div className="hidden md:block">
      </div>
          <Routes>
            {/* <Route path='/' element={<LandingLaptop/>}/> */}
            <Route path='*' element={<NotFound/>}/>
            <Route path="/" element={<HomeDesktop />} />
            <Route path='/Landing' element={<LandingLaptop/>}/>

          </Routes>
    </Router>
  );
}

export default App;
